/* THIS FILE CONVERTS FUNCTION-BASED CONTEXTS SO THEY CAN BE USED IN CLASS-BASED FILES */
import React from 'react';
import { useEnvironmentConfigContext } from './EnvironmentConfigContext'; // Adjust the path as needed

const withEnvironmentConfigContext = (Component) => {
    return function WrappedComponent(props) {
        const environmentConfigContext = useEnvironmentConfigContext();
        return <Component {...props} environmentConfigContext={environmentConfigContext} />;
    };
};

export default withEnvironmentConfigContext;