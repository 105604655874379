import React, { useState, useEffect } from "react";
 import "../../styles/nimbly2.css";
import {
    Chart,
    ChartArea,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import loadinggif from "../../assets/loading.gif";
import { useProject } from '../../contexts/ProjectContext';
import { useEnvironmentConfigContext } from '../../contexts/EnvironmentConfigContext';
import { DropDownList } from "@progress/kendo-react-dropdowns";


const CSDashoboard = ({ onPageChange }) => {
    const [expanded, setExpanded] = React.useState(1);
    const [firstSeriesRemoved, setfirstSeriesRemoved] = React.useState([]);
    const [categoriesRemoved, setcategoriesRemoved] = React.useState([]);
    const [firstSeries, setfirstSeries] = React.useState([]);
    const [categories, setcategories] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const { selectedProject, GCP_Project, Dataset_ID } = useProject();
    const { environmentConfig } = useEnvironmentConfigContext();
    const [data, setData] = React.useState();
    const [yearList, setYearList] = React.useState([]);


    useEffect(() => {
        if (selectedProject && GCP_Project && Dataset_ID) {
            populateChartData();
        }
    }, [selectedProject, GCP_Project, Dataset_ID]);


    const gridWidth = 600;

    const setPercentage = (percentage) => {
        return Math.round(gridWidth / 100) * percentage;
    };
    const ChartContainerStatus = () => (
        <Chart>
            <ChartArea height={300} />
            <ChartTitle text="" />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={categories}>
                </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartSeries>
                <ChartSeriesItem
                    type="bar"
                    gap={1}
                    spacing={1}
                    data={firstSeries}
                    categoryField="Status" field="Counts" colorField="Color"
                    tooltip={{ visible: true, }}
                />
                {/*               //{firstSeries.length > 0 ? firstSeries.map((item, idx) => (
              //    <ChartSeriesItem
              //        type="bar"
              //        gap={1}
              //        spacing={1}
              //        //color={item.Color}
              //        key={idx}
              //        data={item.Counts}
              //        //name={item.name}
              //        tooltip={{ visible: true, }}
              //    /> 
              //)) : null} */}

            </ChartSeries>
        </Chart>
    );

    const ChartContainerRemoved = () => (
        <Chart>
            <ChartArea height={300} />
            <ChartTitle text="" />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={categoriesRemoved}>
                </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartSeries>
                <ChartSeriesItem type="column" gap={1} spacing={1} data={firstSeriesRemoved} color={"#FADB14"} tooltip={{ visible: true, }} />
            </ChartSeries>
        </Chart>
    );

    const handleDivClick = (pageName) => {
        onPageChange(pageName);
    };

    const barColor = (status) => {
        if (status) {
            switch
            (status) {
                case
                    "Pulled":
                    return "#000080"
                case
                    "Not Doublewood":
                    return "#000080"
                case
                    "Closed":
                    return "#000080"
                case
                    "Ready to Go":
                    return "#32CD32"
                case
                    "Heavy Vegetation":
                    return "#FF8C00"
                case
                    "Pole Not Set":
                    return "#FF8C00"
                case
                    "Waiting":
                    return "#FF8C00"
                case
                    "Pole Needs Topping":
                    return "#FF8C00"
                case
                    "Electric Power":
                    return "#FF8C00"
                case
                    "No Access":
                    return "#FF8C00"
                case
                    "Complex Transfers":
                    return "#FFD800"
                case
                    "Foreign Owned":
                    return "#8B0000"
                case
                    "Does Not Exist":
                    return "#8B0000"
                default:
                    return "#000000"
            }
        }
        return "#000000"
    }

    const handleChange = (event) => {
        let selectedYear = event.target.value;
        var listS = [], listC = [];
        data.CSPoleRemoved.map((x) => (x.Year == selectedYear ? listC.push(x.Month) : null, x.Year == selectedYear ? listS.push(x.Counts) : null));
        setcategoriesRemoved(listC);
        setfirstSeriesRemoved(listS);
    };


    const populateChartData = () => {

        //console.log("populateChartData");
        setLoading(true);
        fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Secrets/GetNimblyWebAPIKey', {
            method: 'GET',
            headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" },
            //mode: 'no-cors',
        })
            .then((res) => {
                return res.text();;
            })
            .then((data) => {
                fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/GetPulseChart', {
                    method: 'POST',
                    headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', 'ApiKey': data, },
                    body: JSON.stringify({
                        rawQuery: "",
                        Project: selectedProject,
                        GCP_Project: GCP_Project,
                        Dataset_ID: Dataset_ID
                    })
                })
                    .then((res) => {
                        return res.json();;
                    })
                    .then((data) => {

                        const date = new Date();
                        var currentYear = date.toString().split(" ")[3]
                        var yearList = [2021]
                        var startingYear = 2021;
                        for (let i = 0; i < Number(currentYear); i++) {
                            startingYear = startingYear + 1
                            yearList.push(startingYear)
                            if (startingYear == Number(currentYear)) {
                                break;
                            }
                        }

                        setYearList(yearList);
                        for (let i = 0; i < yearList.length; i++) {
                            var selectedYear = yearList[i];
                            for (let j = 0; j < 12; j++) {
                                var exist = data.CSPoleRemoved.find(el => el.Year == selectedYear && el.Month == j + 1);
                                if (exist === undefined) {
                                    let myObject = { Year: selectedYear.toString(), Month: (j + 1).toString().padStart(2, 0), Count: 0 }; // create an object
                                    data.CSPoleRemoved.push(myObject); // add the object to the array
                                }
                            }
                        }

                        data.CSPoleRemoved.sort(function (a, b) {
                            return a.Year.localeCompare(b.Year) || a.Month - b.Month;
                        });

                        var MonthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                        data.CSPoleRemoved.map((x) => (x.Month = MonthArray[Number(x.Month) - 1]));

                        setData(data);

                        var listS = [], listC = [], listSS = [], listSC = [];
                        data.CSPoleRemoved.map((x) => (x.Year == currentYear ? listC.push(x.Month) : null, x.Year == currentYear ? listS.push(x.Counts) : null));
                        //data.CSPoleStatus.map((x) => (listSC.push(x.Status), listSS.push(x.Counts)));
                        data.CSPoleStatus.map((x) => (x.Color = barColor(x.Status)));

                        setcategoriesRemoved(listC);
                        setfirstSeriesRemoved(listS);
                        setcategories(listSC);
                        setfirstSeries(data.CSPoleStatus);
                        //console.log(data.CSPoleStatus);
                        setLoading(false);
                    });
            });
    }
    return (
        <div className="ken-nimbly-ov-page-content-wrapper">
            <div className="pulse-dashboard-chart-title">
                <p className="pulse-chart-main-title">Chart View:</p>
            </div>
        <GridLayout
            className="pulse-dashboard-chart-title-cs"
                  gap={{
                    rows: 1,
                    cols: 3,
                  }}
                  cols={[
                    {
                      width: "50%",
                    },
                    {
                      width: "29%",
                    }
                    ,
                    {
                      width: "20%",
                    }
                  ]}
            >
          <GridLayoutItem  row={1} col={1}>
                  <p className="pulse-chart-title">Pole Count by Status:</p>
          </GridLayoutItem>
          <GridLayoutItem  row={1} col={2}>
                  <p className="pulse-chart-title" >Poles Removed:</p>
          </GridLayoutItem>
          <GridLayoutItem  row={1} col={3}>
          <p className="pulse-chart-title-span">*showing year to date only</p>
          </GridLayoutItem>
        </GridLayout>
              <GridLayout
            gap={{
              rows: 1,
              cols: 2,
            }}
            rows={[
              {
                height: "100%",
              },
              {
                height: "100%",
              }
            ]}
            cols={[
              {
                width: "50%",
              },
              {
                width: "50%",
              }
            ]}
          >
              <GridLayoutItem row={1} col={1}>
              <div className="pulse-chart-container">
              {loading ?
                <img className='nimbly2-loader-gif' src={loadinggif} /> 
                : <ChartContainerStatus />}            
              </div>
              </GridLayoutItem>
              <GridLayoutItem row={1} col={2}>
                                    <div className="pulse-chart-container">
                                        {loading ? <img className='nimbly2-loader-gif' src={loadinggif} /> :
                                            <>
                                                <span>Select Year: </span>
                                                <DropDownList
                                                    style={{
                                                        width: "100px",
                                                    }}
                                                    data={yearList}
                                                    defaultValue={Number(new Date().toString().split(" ")[3])}
                                                    onChange={handleChange}
                                                />
                                                <ChartContainerRemoved />
                                            </>
                                        }
                                    </div>
              </GridLayoutItem>
              </GridLayout>
    </div>
  )
}
export default CSDashoboard;
