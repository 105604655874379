import React, { useState, useEffect } from "react";
//import { DateRangePicker as KenDateRangePicker } from "@progress/kendo-react-dateinputs";
import "../../styles/nimbly2.css";
import {
    Chart,
    ChartArea,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisTitle,
    ChartCategoryAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import CSMapPage from "../construction_services/cs_mapcontainer";
import RSMapPage from "../reliability_services/rs_mapcontainer";
import {
    ExpansionPanel,
    ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import loadinggif from "../../assets/loading.gif";
import { useProject } from '../../contexts/ProjectContext';
import { useEnvironmentConfigContext } from '../../contexts/EnvironmentConfigContext';

const titkeElement = () => (
    <div>
        <p className="pulse-chart-title">Pole Count by Status:</p>
        <p className="pulse-chart-title">Pole Count by Status:</p>

    </div>
);

const MapPage = ({ onPageChange }) => {
    const [expanded, setExpanded] = React.useState(1);
    const [firstSeriesRemoved, setfirstSeriesRemoved] = React.useState([]);
    const [categoriesRemoved, setcategoriesRemoved] = React.useState([]);
    const [firstSeries, setfirstSeries] = React.useState([]);
    const [categories, setcategories] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const { selectedProject, GCP_Project, Dataset_ID, segment } = useProject();
    const { environmentConfig } = useEnvironmentConfigContext();

    useEffect(() => {
        if (selectedProject && GCP_Project && Dataset_ID) {
            populateMapData();
        }
    }, [selectedProject, GCP_Project, Dataset_ID]);

    const barColor = (status) => {
        if (status) {
            switch
            (status) {
                case
                    "Pulled":
                    return "#000080"
                case
                    "Not Doublewood":
                    return "#000080"
                case
                    "Ready to Go":
                    return "#32CD32"
                case
                    "Heavy Vegetation":
                    return "#FF8C00"
                case
                    "Pole Not Set":
                    return "#FF8C00"
                case
                    "Electric Power":
                    return "#FF8C00"
                case
                    "Pole Needs Topping":
                    return "#FF8C00"
                case
                    "Electric Power":
                    return "#FF8C00"
                case
                    "No Access":
                    return "#FF8C00"
                case
                    "Complex Transfers":
                    return "#FFD800"
                case
                    "Foreign Owned":
                    return "#8B0000"
                case
                    "Does Not Exist":
                    return "#8B0000"
                default:
                    return "#000000"
            }
        }
        return "#000000"
    }

    const populateMapData = () => {

        //console.log("populateMapData");
        setLoading(true);
        fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Secrets/GetNimblyWebAPIKey', {
            method: 'GET',
            headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" },
            //mode: 'no-cors',
        })
            .then((res) => {
                return res.text();;
            })
            .then((data) => {
                //console.log(data);
                fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/GetPulseChart', {
                    method: 'POST',
                    headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', 'ApiKey': data, },
                    //mode: 'no-cors',
                    //headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        rawQuery: "",
                        Project: selectedProject,
                        GCP_Project: GCP_Project,
                        Dataset_ID: Dataset_ID
                    })
                })
                    .then((res) => {
                        return res.json();;
                    })
                    .then((data) => {
                        //console.log(data);
                        var listS = [], listC = [], listSS = [], listSC = [];
                        data.CSPoleRemoved.map((x) => (listC.push(x.Month), listS.push(x.Counts)));
                        //data.CSPoleStatus.map((x) => (listSC.push(x.Status), listSS.push(x.Counts)));
                        data.CSPoleStatus.map((x) => (x.Color = barColor(x.Status)));

                        setcategoriesRemoved(listC);
                        setfirstSeriesRemoved(listS);
                        setcategories(listSC);
                        setfirstSeries(data.CSPoleStatus);
                        //console.log(data.CSPoleStatus);
                        setLoading(false);
                    });
            });
    }

    return (
        <div className="ken-nimbly-map-page-content-wrapper">
            <p className="pulse-chart-title" style={{ marginLeft: "0px" }}>Map View:</p>
            {segment === "Doublewood" ? <CSMapPage /> : <RSMapPage />}
        </div>
    )
}
export default MapPage;
