import React, { createContext, useContext, useState, useEffect } from 'react';
import { useProject } from '../contexts/ProjectContext';
import { useHighlightedPoles } from '../contexts/HighlightedPolesContext';

const EnvironmentConfigContext = createContext();

export const EnvironmentConfigProvider = ({ children }) => {
    const [environmentConfig, setEnvironmentConfig] = useState("prod");
    //const { selectedProject, setSelectedProject, setGCP_Project, setDataset_ID, setSegment, previousProject, handleProjectChange, setPreviousProject } = useProject();
    //const { setHighlightedPoles } = useHighlightedPoles();

    useEffect(() => {
        // This code will run after every state update (count change)
        console.log('Count updated:', environmentConfig);
        //setHighlightedPoles([]);
        //setQueryResults([]);
        //setSelectedTab(0);
        //setSelectedProject('');
        //setPreviousProject(null);
        //handleProjectChange('');
        //setGCP_Project('');
        //setDataset_ID('');
        //setSegment('');
        //fetchProjects();


        //debugger;
        //// append nimbleversion request header to all fetch calls
        //var originalFetch = window.fetch;
        //window.fetch = function (input, init) {
        //    debugger;
        //    if (!init) {
        //        init = {};
        //    }
        //    if (!init.headers) {
        //        init.headers = new Headers();
        //    }
        //    //debugger;
        //    // init.headers could be: 
        //    //   `A Headers object, an object literal, 
        //    //    or an array of two-item arrays to set request�s headers.`
        //    if (init.headers instanceof Headers) {
        //        init.headers.delete("frontendEnvironmentConfig");
        //        init.headers.append('frontendEnvironmentConfig', environmentConfig);
        //    } else if (init.headers instanceof Array) {
        //        init.headers.delete("frontendEnvironmentConfig");
        //        init.headers.push(['frontendEnvironmentConfig', environmentConfig]);
        //    } else {
        //        init.headers['frontendEnvironmentConfig'] = environmentConfig;
        //    }
        //    return originalFetch(input, init);
        //};


    }, [environmentConfig]); // The dependency array ensures this effect runs only when 'count' changes


    return (
        <EnvironmentConfigContext.Provider value={{ environmentConfig, setEnvironmentConfig }}>
            {children}
        </EnvironmentConfigContext.Provider>
    );
};

export const useEnvironmentConfigContext = () => useContext(EnvironmentConfigContext);

