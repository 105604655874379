import React, { useState, useEffect } from "react";
import NimblyHeader from "./global_components/header";
import NimblyNavbar from "./global_components/navbar";
import TopNavBar from "./global_components/top_nav";
// import MapPage from "./map_page";
// import BudgetPage from "./budget_page";
// import AnalyticalPage from "./analytical_page";
// import ReportPage from "./report_page";
// import OverviewMainPage from "./overview_page";
// import ExpandedPoleConditionsPage from "./pole_cond_expnded";
// import ExpandedInpectionItemsPage from "./inspection_itms_expnded";
// import ExpandedInspectionAddersPage from "./inspection_adders_expnded";
// import NimblyLoginPage from "./login";
import { Outlet } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";
// import { BrowserRouter, Routes, Route, Navigate, useNavigate, useParams, useMatch } from "react-router-dom";
import loadinggif from "./assets/loading.gif";
import loadingtext from "./assets/loadingmaps.png";
import noaccess from "./assets/images/noaccess.png";
import noaccesstext from "./assets/images/noaccesscontent.png";
import NimblyLoginPage from "./pages/global/login";
import { useUser } from './contexts/UserContext';  // Import the custom UserContext
import { useEnvironmentConfigContext } from './contexts/EnvironmentConfigContext';

const NimblyRender = () => {
    const [currentPage, setCurrentPage] = useState("map");  // State for the current page
    const { user: auth0User, isAuthenticated, isLoading } = useAuth0();  // Destructure Auth0 properties
    const { user, setUser } = useUser();  // Access user state and setUser function from the UserContext
    const { environmentConfig } = useEnvironmentConfigContext();

    useEffect(() => {
        // Check if the user is authenticated
        if (isAuthenticated) {
            //console.log("Auth0 User Object before any modifications:", auth0User);
            handleUserPermission();  // Fetch additional user permissions and update the user object
        } else {
            setUser(null); // Clear user context when not authenticated
        }
    }, [isAuthenticated]);

    const handleUserPermission = () => {
        if (isAuthenticated && auth0User) {
            //console.log("User email for API call:", auth0User.email);

            // First API call to get the API key for further requests
            fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Secrets/GetNimblyWebAPIKey', {
                method: 'GET',
                headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" },
            })
                .then((res) => res.text())
                .then((apiKey) => {
                    //console.log("Received API Key:", apiKey);

                    // Second API call to fetch user-specific permissions based on email
                    fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/GetAuth0UsersPermission', {
                        method: 'POST',
                        headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', 'ApiKey': apiKey, },
                        body: JSON.stringify({
                            UserEmail: auth0User.email,
                        })
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            //console.log("Fetched data from API:", data);

                            const roleFromAPI = data[0] ?.Roles || "User";  // Default to "User" if no role is provided
                            const projectFromAPI = data[0] ?.Project || "";  // No default for project, remains null if not provided

                            //console.log("Role from API:", roleFromAPI);
                            //console.log("Project from API:", projectFromAPI);

                            // Create a new user object that includes the original Auth0 user object and adds the role and project
                            const updatedUser = {
                                ...auth0User, // Spread the original properties from Auth0 user object
                                Roles: roleFromAPI,  // Add role from API response
                                Project: projectFromAPI,  // Add project from API response
                            };

                            //console.log("Updated user object with API data:", updatedUser);

                            // Store the full user object in context for global access across the application
                            setUser(updatedUser);
                            //console.log(updatedUser);
                        });
                });
        }
    };

    const handlePageChange = (pageName) => {
        setCurrentPage(pageName);
    };

    // Show a loading spinner while Auth0 is loading user data
    if (isLoading) {
        return (
            <div className='nimbly2-map-loader'>
                <img src={loadinggif} alt="Loading" />
            </div>
        );
    }

    // Render the application if the user is authenticated and the user object has been set, otherwise show a loading spinner
    if (isLoading || (isAuthenticated && !user)) {
        return (
            <div className='nimbly2-map-loader'>
                <img src={loadinggif} alt="Loading" />
            </div>
        );
    }

    // Once authenticated and user is set, render the application, otherwise show the login page
    return (
        isAuthenticated && user ?
            <>
                {user && <NimblyHeader user={user} />}
                <NimblyNavbar onPageChange={handlePageChange} />
                <TopNavBar />
                {user.Project != "" && user.Project != null ?
                    <Outlet context={user} /> : <><img className='pulse-no-access-image' src={noaccess} /><img className='pulse-no-access-image-text' src={noaccesstext} /></>

                }
            </> : <NimblyLoginPage />


        // Original commented out conditional rendering approach
        // isAuthenticated && (
        //   <>
        //   <NimblyHeader user={user}/>
        //   <NimblyNavbar onPageChange={handlePageChange} />
        //   <TopNavBar />
        //   <Outlet />
        // </>
        // )

        // Original commented out approach for fallback rendering
        // <div>
        //   {user === undefined ? null : (isAuthenticated != false ? 
        //     <>
        //       <NimblyHeader user={user}/>
        //       <NimblyNavbar onPageChange={handlePageChange} />
        //       <TopNavBar />
        //       <Outlet />
        //     </>
        //      : <Navigate to="/login" replace />)
        //   }
    );
};

export default NimblyRender;
