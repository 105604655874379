import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DateRangePicker as KenDateRangePicker } from "@progress/kendo-react-dateinputs";
import { ButtonGroup as KenButtonGroup, Button as KenButton } from "@progress/kendo-react-buttons";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { Grid as KenGrid, GridColumn as KenColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { xIcon } from "@progress/kendo-svg-icons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import loadinggif from "../../assets/loading.gif";
import { process } from "@progress/kendo-data-query";
import { useProject } from '../../contexts/ProjectContext';
import { useEnvironmentConfigContext } from '../../contexts/EnvironmentConfigContext';
import { useHighlightedPoles } from '../../contexts/HighlightedPolesContext';
import { useQueryResults } from '../../contexts/QueryResultsContext';
import { useTabContext } from '../../contexts/ReportTabContext';

function ReliabilityReportPage() {
    //states to hold the categories from the webapi
    const [rejectStatusList, setRejectStatusList] = useState([]);
    const [inspectionConditionList, setInspectionConditionList] = useState([]);
    const [feederList, setFeederList] = useState([]);
    const [structureNumberList, setStructureNumberList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);

    //states to hold values for searching
    const [rejectStatus, setRejectStatus] = useState("");
    const [inspectionCondition, setInspectionCondition] = useState("");
    const [feeder, setFeeder] = useState("");
    const [structureNumber, setStructureNumber] = useState("");
    const [startAuditDate, setStartAuditDate] = useState("");
    const [endAuditDate, setEndAuditDate] = useState("");

    //utility states / other utilities
    const { queryResults, setQueryResults, isQueryActive } = useQueryResults();
    const { selectedProject, GCP_Project, Dataset_ID, previousProject, handleProjectChange } = useProject();
    const { highlightedPoles, setHighlightedPoles } = useHighlightedPoles();
    const { selectedTab, setSelectedTab } = useTabContext();
    const [loading, setLoading] = useState(true);
    const [showWarning, setShowWarning] = useState(false);
    const [allExpanded, setAllExpanded] = useState();
    const [dataState, setDataState] = useState({ skip: 0, take: 10 });
    const [dataResult, setDataResult] = useState(process(queryResults, dataState));
    const min = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    const excelExportRef = useRef(null);
    const gridWidth = 600;
    const navigate = useNavigate();
    const { environmentConfig } = useEnvironmentConfigContext();

    //returns bool if there is at least one valid entry 
    function isValidSubmit() {
        return (isValidEntry(rejectStatus) || isValidEntry(inspectionCondition) || isValidEntry(formatStructureNumberList(selectedList)) ||
                isValidEntry(feeder) || isValidEntry(startAuditDate) || isValidEntry(endAuditDate));
    }

    //determines if an entry is valid
    function isValidEntry(entry) {
        return !(entry == null || entry == "");
    }

    function resetQueryOptions() {
        setRejectStatus(null);
        setInspectionCondition(null);
        setFeeder(null);
        setStructureNumber(null);
        setStartAuditDate(null);
        setEndAuditDate(null);
        setStructureNumber(null);
        setSelectedList(null);
    }

    function fetchQueryResults() {
        setLoading(true);
        try {
            fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Secrets/GetNimblyWebAPIKey', {
                method: 'GET',
                headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json' }
            })
                .then((res) => res.text())
                .then((apiKey) => {
                    fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/GetRSPoles', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'ApiKey': apiKey
                        },
                        body: JSON.stringify({
                            StartDateAudit: startAuditDate || "",
                            EndDateAudit: endAuditDate || "",
                            RejectStatus: rejectStatus || "",
                            InspectionCondition: inspectionCondition || "",
                            Feeder: feeder || "",
                            StructureNumber: formatStructureNumberList(selectedList) || "",
                            Project: selectedProject,
                            GCP_Project: GCP_Project,
                            Dataset_ID: Dataset_ID,
                            Pull_Type: ""
                        })
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            const flattenedData = data.map(item => ({
                                GlobalId: item.GlobalId,
                                fieldDict: item.fieldDict,
                                photosDict: item.photosDict
                            }));
                            setSelectedTab(1);
                            setQueryResults(flattenedData);
                            resetQueryOptions();
                            setDataState({
                                skip: 0,
                                take: 10
                            });
                            setAllExpanded(false);
                            setLoading(false);
                        });
                });
        } catch (ex) {
            console.log("<------------------------------>");
            console.log("Error executing query");
            console.log(ex);
        }     
    }

    function populateDropdownData() {
        setLoading(true);
        try {
            fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Secrets/GetNimblyWebAPIKey', {
                method: 'GET',
                headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" },
            })
                .then((res) => {
                    return res.text();
                })
                .then((data) => {
                    fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/GetRSCategories', {
                        method: 'POST',
                        headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', 'ApiKey': data, },
                        //headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json'},
                        body: JSON.stringify({
                            rawQuery: "",
                            Project: selectedProject,
                            GCP_Project: GCP_Project,
                            Dataset_ID: Dataset_ID
                        })
                    })
                        .then((res) => {
                            return res.json();
                        })
                        .then((data) => {
                            //Each item being passed from data is a dictionary<string, byte>
                            //Since there is no concurrent set in C# this was the best alternative for concurrency
                            setRejectStatusList(Object.keys(data.RejectStatus));
                            setInspectionConditionList(Object.keys(data.InspectionCondition));
                            setFeederList(Object.keys(data.Feeder));
                            setStructureNumberList(Object.keys(data.StructureNumber));
                            setLoading(false);
                        });
                });
        } catch (ex) {
            console.log("<------------------------------>");
            console.log("Error fetching rs categories");
            console.log(ex);
        } 
    }

    const flattenObject = (obj, parentKey = '', result = {}) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                const newKey = parentKey ? `${parentKey}.${key}` : key;
                if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                    flattenObject(obj[key], newKey, result);
                } else {
                    result[newKey] = obj[key];
                }
            }
        }
        return result;
    };

    const exportData = useMemo(() => {
        let dataArray = [];
        queryResults.forEach((item, index) => {
            // Flatten the Poles data to access fields easily
            const mainRowData = flattenObject(item.fieldDict ?.Poles || {});

            // Main Row with 'Row Type', 'Structure ID', 'Audit Date'
            const mainRow = {
                'Row Type': `Pole Result ${index + 1}`,
                'Structure ID': mainRowData['Structure ID'] || '',
                'Audit Date': mainRowData['Audit Date'] || '',
                // All other fields are left empty
            };
            dataArray.push(mainRow);

            // Location Details Row
            if (item.fieldDict ?.Location_Details) {
                const locationData = flattenObject(item.fieldDict.Location_Details);
                const locationRow = {
                    'Row Type': 'Location Details',
                    ...locationData,
                };
                dataArray.push(locationRow);
            }

            // Pole Information Row
            if (item.fieldDict ?.Poles) {
                const poleInfoData = flattenObject(item.fieldDict.Poles);
                const poleInfoRow = {
                    'Row Type': 'Pole Information',
                    ...poleInfoData,
                };
                dataArray.push(poleInfoRow);
            }

            // Include photosDict or other nested data if necessary
        });
        return dataArray;
    }, [queryResults]);

    const columns = useMemo(() => {
        // Predefined columns in the desired order
        const predefinedColumns = ['Row Type', 'Structure ID', 'Audit Date'];

        // Collect all keys from exportData
        const allColumnKeys = new Set();
        exportData.forEach(item => {
            Object.keys(item).forEach(key => {
                allColumnKeys.add(key);
            });
        });

        // Filter out predefined columns to get additional columns
        const additionalColumns = Array.from(allColumnKeys).filter(key => !predefinedColumns.includes(key));

        // Combine predefined columns and additional columns
        const allColumns = predefinedColumns.concat(additionalColumns);

        // Map to column definitions
        return allColumns.map(key => ({
            field: key,
            title: key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
        }));
    }, [exportData]);

    const exportRows = () => {
        if (excelExportRef.current && queryResults.length > 0) {
            excelExportRef.current.save();
        } else {
            alert("No data available to export.");
        }
    };

    function submitQuery() {
        if (!isValidSubmit()) {
            setShowWarning(true);
            return;
        }
        setShowWarning(false);
        fetchQueryResults();
    }

    function expandChange(event){
        const isExpanded = event.dataItem.expanded === undefined ? event.dataItem.aggregates : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setDataResult({
            ...dataResult,
            data: [...dataResult.data],
        });
    };

    function expandAllRows(shouldExpand) {
        let updatedExpandStatus = !allExpanded;
        let newData = queryResults.map((item) => {
            item.expanded = updatedExpandStatus
            return item;
        });
        setDataResult({
            ...dataResult,
            data: [...dataResult.data],
        });
        setAllExpanded(!allExpanded);
    };

    const viewInMap = () => {
        const highlightedPolesList = queryResults.map(item => item.GlobalId);
        setHighlightedPoles(highlightedPolesList);
        const updatedPoleData = queryResults.map(item => ({
            ...item,
            highlighted: highlightedPolesList.includes(item.GlobalId)
        }));

        navigate('/map');
    };

    function dataStateChange(event) {
        setDataResult(process(queryResults, event.dataState));
        setDataState(event.dataState);
    };

    function setPercentage(percentage) {
        return Math.round(gridWidth / 100) * percentage;
    }

    function handleStructureNumberChange(e) {
        const tempNumber = e.target.value;
        const isSelected = structureNumberList.includes(tempNumber);
        const inStructureNumberList = selectedList != null ? selectedList.includes(tempNumber) : false;
        if (isSelected && !inStructureNumberList) {
            if (selectedList != null) {
                setStructureNumber(e.target.value);
                setSelectedList(oldArray => [tempNumber, ...oldArray]);
            } else {
                let temp = [tempNumber];
                setStructureNumber(e.target.value);
                setSelectedList(temp);
            }
        } else {
            setStructureNumber(e.target.value);
        }
    };

    function formatStructureNumberList(li){
        let ret = "";
        if (li != null) {
            li.forEach(el => {
                ret += "\"" + el + "\"" + ",";
            });
        }
        return ret.substr(0, ret.length - 1);
    }

    useEffect(() => {
        if (selectedProject) {
            populateDropdownData();
            resetQueryOptions();
            setDataResult(process([], dataState));
        }
        setDataResult({
            ...dataResult,
            data: [...dataResult.data],
        });
    }, [selectedProject]);

    useEffect(() => {
        const allRowsExpanded = queryResults.length > 0 && queryResults.every(item => item.expanded);
        setAllExpanded(allRowsExpanded);
    }, [queryResults]);

    useEffect(() => {
        setDataResult(process(queryResults, dataState));
    }, [queryResults]);

    function SelectedPoleNumbers({ props }) {
        if (props != null) {
            return (
                <div className="pulse-multi-pole-wrapper">
                    {props.map((num) => (
                        <div className="pulse-multi-pole">
                            <div className="pulse-multi-pole-label">{num}</div>
                            <Button
                                svgIcon={xIcon}
                                onClick={() => setSelectedList(selectedList.filter(a => a != num))}
                                className="pulse-multi-pole-x"
                                fillMode="link"
                            />
                        </div>
                    ))}
                </div>
            )
        } else {
            return <div />
        }
    };

    //detail components for the datagrid
    function DetailComponent(props) {
        //Current there is no attachments for rs projects
        //If added they will need to be reimplemnted
        const dataItem = props.dataItem;
        let last = 3;
        let first = 1;
        const picCol = dataItem.photosDict && Object.keys(dataItem.photosDict).length > 0 ? first++ : last--;
        const locationCol = first++;
        const infoCol = first++;
        const renderFieldItems = (fields) => {
            return Object.entries(fields).map(([key, value], index) => (
                <React.Fragment key={index}>
                    <GridLayoutItem className="pulse-queryresult-expanded-detail-column" row={index + 2} col={1}>
                        {"Pulse " + key.replace(/_/g, ' ')}:
                </GridLayoutItem>
                    <GridLayoutItem row={index + 2} col={2}>
                        {value}
                    </GridLayoutItem>
                </React.Fragment>
            ));
        };

        function Photos() {
            return (
                <GridLayoutItem className="pulse-img-container" row={1} col={picCol}>
                    {dataItem.photosDict && Object.values(dataItem.photosDict).map((photo, x) => (
                        <a href={photo['Photo Path']} target="_blank" rel="noopener noreferrer" key={x}>
                            <img className="pulse-img" src={photo['Photo Path']} alt={`Photo ${x + 1}`} />
                        </a>
                    ))}
                </GridLayoutItem>
            );
        }

        function Info() {
            return (
                <GridLayoutItem className="pulse-expand-content pulse-img-container" row={1} col={infoCol}>
                    <GridLayout
                        gap={{ rows: 14, cols: 2, }}
                        cols={[
                            { width: "49%", },
                            { width: "49%", }
                        ]}
                    >
                        <GridLayoutItem className="pulse-expand-title" row={1} col={1} colSpan={2}>
                            Pole Information
                    </GridLayoutItem>
                        {renderFieldItems(dataItem.fieldDict.Poles)}
                    </GridLayout>
                </GridLayoutItem>
            );
        }

        function Location() {
            return (
                <GridLayoutItem className="pulse-expand-content pulse-img-container" row={1} col={locationCol}>
                    <GridLayout
                        gap={{ rows: 8, cols: 2, }}
                        cols={[
                            { width: "49%", },
                            { width: "49%", }
                        ]}
                    >
                        <GridLayoutItem className="pulse-expand-title" row={1} col={1} colSpan={2}>
                            Location Details
                        </GridLayoutItem>
                        {renderFieldItems(dataItem.fieldDict.Location_Details)}
                    </GridLayout>
                </GridLayoutItem>
            );
        }

        return (
            <GridLayout
                gap={{ rows: 2, cols: 4, }}
                rows={[
                    { height: 250, }
                ]}
                cols={[
                    { width: "25%", },
                    { width: "25%", },
                    { width: "25%", },
                    { width: "25%", }
                ]}
            >
                {dataItem.photosDict && Object.keys(dataItem.photosDict).length > 0 ? <Photos /> : null}
                <Info />
                <Location />
            </GridLayout>
        )
    }

    const Warning = <div className="loading-icon-container"><img className='nimbly2-loader-gif' src={loadinggif} /> </div>;

    return (
        <div className="ken-nimbly-page-content-wrapper">
            {loading ? Warning : null}
            <TabStrip selected={selectedTab} onSelect={(e) => setSelectedTab(e.selected)}>
                <TabStripTab title="QUERY WIZARD">
                    <p className="ken-nimbly-ov-main-title">Query Wizard</p>
                    <div className="pulse-wizard-container">
                        <div className="pulse-wizard-container-header">Generate Query</div>
                        <div className={!showWarning ? "pulse-warning-no-display" : "pulse-wizard-container-warning"}>Please fill in at least one valid value to execute query.</div>
                        <div className="pulse-wizard-container-2">
                            <GridLayout
                                gap={{ rows: 2, cols: 4, }}
                            >
                                <GridLayoutItem row={1} col={1} colSpan={4}>
                                    <p className="Pulse-report-form-title">AUDIT DATE</p>
                                    <div className="box-divider"></div>
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={2} col={1}>
                                    Start Date:
                                </GridLayoutItem>
                                <GridLayoutItem row={2} col={2}>
                                    <DatePicker min={min} placeholder="Choose a date..." onChange={(e) => setStartAuditDate(e.target.value)} />
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={2} col={3}>
                                    End Date:
                                </GridLayoutItem>
                                <GridLayoutItem row={2} col={4}>
                                    <DatePicker min={min} placeholder="Choose a date..." onChange={(e) => setEndAuditDate(e.target.value)} />
                                </GridLayoutItem>
                            </GridLayout>
                            <GridLayout
                                gap={{ rows: 8, cols: 2, }}
                                cols={[
                                    { width: 150 }
                                ]}
                            >
                                <GridLayoutItem row={1} col={1} colSpan={7}>
                                    <p className="Pulse-report-form-title">POLE INFO</p>
                                    <div className="box-divider"></div>
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={2} col={1}>
                                    Reject Status:
                                </GridLayoutItem>
                                <GridLayoutItem row={2} col={2} colSpan={6}>
                                    <DropDownList
                                        data={rejectStatusList}
                                        value={rejectStatus}
                                        onChange={(e) => setRejectStatus(e.target.value)}
                                    />
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={3} col={1} >
                                    Inspection Condition:
                                </GridLayoutItem>
                                <GridLayoutItem row={3} col={2} colSpan={6}>
                                    <DropDownList
                                        data={inspectionConditionList}
                                        value={inspectionCondition}
                                        onChange={(e) => setInspectionCondition(e.target.value)}
                                    />
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={4} col={1} >
                                    Feeder:
                                </GridLayoutItem>
                                <GridLayoutItem row={4} col={2} colSpan={6}>
                                    <DropDownList
                                        data={feederList}
                                        value={feeder}
                                        onChange={(e) => setFeeder(e.target.value)}
                                    />
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-querywizard-column-text" row={5} col={1} >
                                    Structure Number:
                                </GridLayoutItem>
                                <GridLayoutItem row={5} col={2} colSpan={6}>
                                    <AutoComplete
                                        data={structureNumberList}
                                        defaultValue=""
                                        value={structureNumber}
                                        onChange={(e) => handleStructureNumberChange(e)}
                                    />
                                    <SelectedPoleNumbers props={selectedList}/>
                                </GridLayoutItem>
                                <GridLayoutItem className="pulse-textalignment-center" row={9} col={2} colSpan={2}>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        className="pulse-wizard-submit-button"
                                        onClick={submitQuery}
                                    >
                                        Execute Query
                                    </Button>
                                </GridLayoutItem>
                            </GridLayout>
                        </div>
                    </div>
                </TabStripTab>
                <TabStripTab title="QUERY REPORT">
                    <div className="pulse-query-wizard-top-row">
                        <p className="ken-nimbly-ov-main-title">Query Report</p>
                        <div className="report-action-btn-group">
                            {/*<Button onClick={exportRows} disabled={queryResults.length === 0} className="pulse-expand-collapse-report-button">
                                <span>Export</span>
                            </Button> THIS IS COMMENTED OUT FOR THE TIME BEING DUE TO JOHNS REQUEST */}
                            <Button onClick={() => viewInMap()} disabled={!isQueryActive} className="pulse-expand-collapse-report-button">
                                <span>View Results in Map</span>
                            </Button>
                            <Button onClick={() => expandAllRows()} disabled={queryResults.length === 0} className="pulse-expand-collapse-report-button">
                                <span>{allExpanded ? "Collapse" : "Expand"} All</span>
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div className="pulse-wizard-container-header">Query Results</div>
                        <ExcelExport
                            ref={excelExportRef}
                            data={exportData}
                            columns={columns}
                            fileName={`QueryResults_${new Date().toISOString()}.xlsx`}
                        />
                        <Grid
                            data={dataResult}
                            {...dataState}
                            onDataStateChange={dataStateChange}
                            detail={DetailComponent}
                            style={{
                                height: "100%",
                            }}
                            expandField="expanded"
                            onExpandChange={expandChange}
                            filterable={true}
                            pageable={{
                                buttonCount: 4,
                                pageSizes: [5, 10, 15, 20],
                            }}
                        >
                            <Column field="fieldDict.Poles.Structure ID" title="Structure ID" width={setPercentage(55)} />
                            <Column field="fieldDict.Poles.Audit Date" title="Audit Date" width={setPercentage(55)} />
                        </Grid>
                    </div>
                </TabStripTab>
            </TabStrip>
        </div>
    );
}

export default ReliabilityReportPage;