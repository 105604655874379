import React, { useState, useEffect } from "react";
import { ChunkProgressBar as KenChunkProgressBar, ProgressBar as KenProgressBar } from "@progress/kendo-react-progressbars";
import { Grid as KenGrid, GridColumn as KenColumn } from '@progress/kendo-react-grid';
import { DateRangePicker as KenDateRangePicker } from "@progress/kendo-react-dateinputs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import csedit from "../../assets/Setting1.png";
import csremove from "../../assets/CloseSquare1.png";
import permissionchecks from "../../assets/Vector.png";
import loadinggif from "../../assets/loading.gif";
import { filterBy } from "@progress/kendo-data-query";
import { Window } from "@progress/kendo-react-dialogs";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Input } from "@progress/kendo-react-inputs";
import { Checkbox } from "@progress/kendo-react-inputs";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { Outlet, useOutletContext } from "react-router-dom";
import { useProject } from '../../contexts/ProjectContext';
import { useEnvironmentConfigContext } from '../../contexts/EnvironmentConfigContext';
import { useUser } from '../../contexts/UserContext';
import noaccess from "../../assets/images/noaccess.png";
import noaccesstext from "../../assets/images/noaccesscontent.png";

const products = [{
    "ProductID": 1,
    "ProductName": "Jiyang Shi",
    "SupplierID": 1,
    "CategoryID": 1,
    "QuantityPerUnit": "10 boxes x 20 bags",
    "UnitPrice": "Admin",
    "UnitsInStock": 39,
    "UnitsOnOrder": 0,
    "ReorderLevel": 10,
    "Discontinued": false,
    "Category": {
        "CategoryID": 1,
        "CategoryName": "jiyang.shi@davey.com",
        "Description": "Soft drinks, coffees, teas, beers, and ales"
    }
}];

const initialFilter = {
    logic: "and",
    filters: [
        {
            field: "ProductName",
            operator: "contains",
            value: "",
        },
    ],
};

const gridWidth = 600;

const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage;
};

const CSUserPage = (props) => {
    const [data, setData] = React.useState([]);
    const [visible, setVisible] = React.useState(false);
    const [visibleRemove, setVisibleRemove] = React.useState(false);
    const [valueAdmin, setvalueAdmin] = React.useState(false);
    const [valueUser, setvalueUser] = React.useState(false);
    const [firstName, setfirstName] = React.useState();
    const [lastName, setlastName] = React.useState();
    const [userid, setUserid] = React.useState();
    const [segmentSelected, setSegmentSelected] = React.useState([]);
    const [Email, setEmail] = React.useState();
    const [Project, setProject] = React.useState();
    const [Role, setRole] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const user = useOutletContext();
    const [projectDropdownItems, setProjectDropdownItems] = useState([]);
    const { selectedProject, GCP_Project, Dataset_ID, segment } = useProject();
    const [filter, setFilter] = React.useState();
    const [viewpermission, setViewpermission] = React.useState(false);
    const { environmentConfig } = useEnvironmentConfigContext();

    useEffect(() => {
        //console.log(user);
        if (selectedProject) {
            populateUserData();
        }
        //console.log(selectedProject, GCP_Project, Dataset_ID, segment);
    }, [selectedProject, GCP_Project, Dataset_ID, segment]);

    useEffect(() => {
        setViewpermission(user.email.includes("davey.com") == true ? true : false);
        const fetchProjects = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Poles/GetProjectAliases', {
                    headers: { 'frontendenvironmentconfig': environmentConfig, },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                const projectItems = data.map(project => ({
                    text: project.Alias,
                    value: project.Dataset_ID,
                    segment: project.Segment
                }));

                setProjectDropdownItems(projectItems);
                //console.log(projectItems);
                // Automatically assign all projects if the user is an admin
                if (Role === "Admin") {
                    const allProjectIds = projectItems.map(project => project.value).join(',');
                    setProject(allProjectIds);
                }
            } catch (error) {
                console.error("Error fetching project aliases", error);
            }
        };

        fetchProjects();
    }, [Role, viewpermission]);


    const toggleDialog = (e) => {
        setVisible(!visible);
        if (e != null) {
            setfirstName(e.name.split(" ")[0]);
            setlastName(e.name.split(" ")[1]);
            setEmail(e.email);
            setProject(e.Project);
            setRole(e.Roles)
            setUserid(e.user_id);
            const selectedProject = e.Project ? e.Project.split(',').map(id => findSegmentById(id)) : [];
            const list = selectedProject.filter((x, i, a) => a.indexOf(x) === i)
            //console.log(selectedProject, list);
            setSegmentSelected(list);
            var a = e.Roles === "Admin" ? [setvalueAdmin(true), setvalueUser(false)] : [setvalueAdmin(false), setvalueUser(true)];
        }

    };

    const toggleRemoveDialog = (e) => {
        setVisibleRemove(!visibleRemove);
        if (e != null && e != "null") {
            setfirstName(e.name.split(" ")[0]);
            setlastName(e.name.split(" ")[1]);
            setEmail(e.email);
            setProject(e.Project);
            setRole(e.Roles);
            setUserid(e.user_id);
            var a = e.Roles === "Admin" ? [setvalueAdmin(true), setvalueUser(false)] : [setvalueAdmin(false), setvalueUser(true)]
        }
    };
    const MyCommandCell = (props) => (
        <td className="edit-user-cell">
            <>
                <div className="edit-user-cell">
                    <img className="pulse-user-edit-button-icon" src={csedit}></img><Button
                        themeColor={"primary"}
                        onClick={() => toggleDialog(props.dataItem)}
                        className="pulse-user-edit-button"
                        disabled={user.Roles != "Admin"}

                    >
                        Edit
              </Button>
                </div>
                <div className="edit-user-cell">
                    <img className="pulse-user-edit-button-icon" src={csremove}></img><Button
                        themeColor={"primary"}
                        onClick={() => toggleRemoveDialog(props.dataItem)}
                        className="pulse-user-edit-button"
                        disabled={user.Roles != "Admin"}
                    >
                        Remove
              </Button>
                </div>
            </>
        </td>
    );

const filterAliases = (items, filter) => {
    if (!filter || !filter.filters || filter.filters.length === 0) {
        return items;
    }

    const projectFilter = filter.filters.find(f => f.field === 'Project');
    if (projectFilter) {
        const filterValue = projectFilter.value ? projectFilter.value.toLowerCase() : "";
        const filterOperator = projectFilter.operator;

        return items.filter(item => {
            const aliases = item.Project
                ? item.Project.split(',').map(id => findAliasById(id)).join(', ')
                : '';

            switch (filterOperator) {
                case "contains":
                    return aliases.toLowerCase().includes(filterValue);
                case "doesnotcontain":
                    return !aliases.toLowerCase().includes(filterValue);
                case "startswith":
                    return aliases.toLowerCase().startsWith(filterValue);
                case "endswith":
                    return aliases.toLowerCase().endsWith(filterValue);
                case "eq":  
                    return aliases.toLowerCase() === filterValue;
                case "neq":  
                    return aliases.toLowerCase() !== filterValue;
                case "isnull":  
                    return !aliases;
                default:
                    return true;
            }
        });
    }

    return filterBy(items, filter);
};

const handleFilterChange = (e) => {
    setFilter(e.filter);
};


  const populateUserData = () => {
      setLoading(true);
    //console.log("populateUserData");
      fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Secrets/GetNimblyWebAPIKey', {
        method: 'GET',
        headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*"},
        //mode: 'no-cors',
      })
    .then((res) => {
      return res.text();          ;
    })
    .then((data) => {
      //console.log(data);
      fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/GetAuth0Users', {
        method: 'POST',
        headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', 'ApiKey': data,},
        //mode: 'no-cors',
        //headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json'},
        body: JSON.stringify({
            rawQuery: "",
            Project: selectedProject,
            GCP_Project: GCP_Project,
            Dataset_ID: Dataset_ID,
            Segment: segment
           })
      })
    .then((res) => {
      return res.json();          ;
    })
    .then((data) => {
                        setData(data)
                        setLoading(false);
                    });
            });
    }

    const findAliasById = (id) => {
        const project = projectDropdownItems.find(project => project.value === id);
        return project ? project.text : "";
    };

    const findSegmentById = (id) => {
        const project = projectDropdownItems.find(project => project.value === id);
        return project ? project.segment : "";
    };


const UpdateUserData = (operation) => {
    setLoading(true);
    //console.log(segmentSelected.length > 0 ? segmentSelected.map(project => project).join(',') : "")
    fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Secrets/GetNimblyWebAPIKey', {
        method: 'GET',
        headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*"},
    })
    .then((res) => res.text())
    .then((data) => {
        return fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/UpdatePulesUser', {
            method: 'POST',
            headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', 'ApiKey': data,},
            body: JSON.stringify({
                UserEmail: Email,
                OperationType: operation,
                Project: Project || "",  // Project is already a comma-separated string
                Role: Role || "User",
                userid: userid,
                segment: segmentSelected.length > 0 ? segmentSelected.map(project => project).join(',') : ""
            })
        });
    })
    .then(() => populateUserData())
    .catch(error => console.error("Error updating user data:", error));
};


    const handleCheckboxChange = (event, type) => {
        if (type === "admin") {
            setvalueAdmin(true);
            setvalueUser(false);
            setRole("Admin");

            // Assign all projects if the user is set as admin
            const allProjectIds = projectDropdownItems.map(project => project.value).join(',');
            setProject(allProjectIds);
            const selectedProject = allProjectIds ? allProjectIds.split(',').map(id => findSegmentById(id)) : [];
            const list = selectedProject.filter((x, i, a) => a.indexOf(x) === i)
            //console.log(selectedProject, list);
            setSegmentSelected(list);
        } else {
            setvalueAdmin(false);
            setvalueUser(true);
            setRole("User");

            // Clear projects if the user is no longer an admin
            setProject("");
        }
    };


    const handleClick = (operation) => {
        setLoading(true);
        setVisible(null);
        setVisibleRemove(null);
        UpdateUserData(operation);
    };

    const handleInputChange = (event, type) => {
        if (type === "Project") {
            const selectedProjects = event.value
                .filter(project => project.value)  // Ensure `value` exists
                .map(project => project.value)  // Map to the value (Dataset_ID)
                .join(',');  // Join into a comma-separated string

            setProject(selectedProjects);

            const selectedProject = selectedProjects ? selectedProjects.split(',').map(id => findSegmentById(id)) : [];
            const list = selectedProject.filter((x, i, a) => a.indexOf(x) === i)
            //var a = Array.from(list, (x) => { value: x })
            //console.log(selectedProject, list);
            setSegmentSelected(list);
        }
    };

    const getFilteredProjects = () => {
        // Split the current Project string into an array
        const currentProjects = Project ? Project.split(',') : [];
        // Filter out the projects that are already assigned
        return projectDropdownItems.filter(item => !currentProjects.includes(item.value));
    };


    return (
        !viewpermission ? <><img className='pulse-no-access-image' src={noaccess} /><img className='pulse-no-access-image-text' src={noaccesstext} /></> : 
            <div className="ken-nimbly-page-content-wrapper">
              {
                loading ? 
                <div className="loading-icon-container"><img className='nimbly2-loader-gif' src={loadinggif} /> </div>
                : null
              }
            <div className="ken-nimbly-ov-main">
                        <p className="ken-nimbly-ov-main-title">Access Management</p>
                    </div>
                    <Grid
                        style={{ height: "600px" }}
                        data={filterAliases(data, filter)}            
                        filterable={true}
                        filter={filter}
                        onFilterChange={handleFilterChange}
                        >
      <GridColumn field="name" title="Name" width={setPercentage(20)} />
      <GridColumn field="email" title="Email" width={setPercentage(20)}/>
        <GridColumn
          field="Project"
          title="Project"
          width={setPercentage(20)}
          cell={(props) => (
            <td>
              {props.dataItem.Project
                ? props.dataItem.Project.split(',')
                    .map(id => findAliasById(id))
                    .join(', ')
                : ''}
            </td>
          )}
        />
    <GridColumn field="Roles" title="User Role" width={setPercentage(20)}/>
      <GridColumn field="ProductID" cell={MyCommandCell} title="Action" filterable={false} width={setPercentage(20)}/>
    </Grid>
    <div>
      {visible && <div className="overlay" onClick={() => toggleDialog(null)}></div>}
      {visible && (
        <Window
          title={"Edit User"}
          onClose={toggleDialog}
          position="center"
          className="pulse-user-window"
          height={600}
          width={700}
        >
          <div>
            <div className="pulse-user-window-header">User Management</div>
            <div className="pulse-user-window-content">
          <GridLayout
          gap={{
            rows: 6,
            cols: 4,
          }}
          rows={[
            {
              height: 45,
            },
            {
              height: 45,
            },
            {
              height: 65,
            },
            {
                height: 75,
            },
            {
              height: 25,
            },
            {
              height: 65,
            },
            {
              height: 25,
            }
          ]}
          cols={[
            {
              width: 75,
            },
            {
              width: "1fr",
            },
            {
              width: 75,
            },
            {
                width: "1fr",
            },
          ]}
        >

            <GridLayoutItem className="pulse-user-column-text" row={1} col={1}>
                First
          </GridLayoutItem>
                                    <GridLayoutItem row={1} col={2}>
                                        <Input defaultValue={firstName} disabled={true} width="100px"/>
                                    </GridLayoutItem>
                                    <GridLayoutItem className="pulse-user-column-text" row={1} col={3}>
                                        Last
          </GridLayoutItem>
            <GridLayoutItem row={1} col={4}>
                <Input defaultValue={lastName} disabled={true} />
            </GridLayoutItem>
            <GridLayoutItem className="pulse-user-column-text" row={2} col={1}>
                Email
          </GridLayoutItem>
                                    <GridLayoutItem row={2} col={2} colSpan={3}>
                                        <Input defaultValue={Email} disabled={true} />
                                    </GridLayoutItem>
                                    <GridLayoutItem className="pulse-user-column-text" row={5} col={1}>
                                        Projects
          </GridLayoutItem>
          <GridLayoutItem row={5} col={2} colSpan={3}>
              <MultiSelect
                className="user-projects-dropdown"
                data={getFilteredProjects()}  // Use the filtered project items
                textField="text"  // Display project aliases
                valueField="value"  // Corresponding Dataset_IDs
                disabled={user.Roles !== "Admin"} // Disable if the current user is not an Admin
                value={Project ? Project.split(',').map(id => ({ value: id, text: findAliasById(id) })) : []}
                onChange={(e) => handleInputChange(e, "Project")}
                />
        </GridLayoutItem>
        <GridLayoutItem className="pulse-user-column-text" row={3} col={1}>
            Business Segment
          </GridLayoutItem>
            <GridLayoutItem row={3} col={2} colSpan={3}>
                <MultiSelect
                    className="user-segment-dropdown"
                    data={segmentSelected}  // Use the filtered project items
                    //textField="text"  // Display project aliases
                    disabled={true} // Disable if the current user is not an Admin
                    defaultValue={segmentSelected}
                />
            </GridLayoutItem>
          <GridLayoutItem className="pulse-user-column-text" row={4} col={1}>
              Role
          </GridLayoutItem>
          <GridLayoutItem row={4} col={2} colSpan={3}>
              <Checkbox 
                className="pulse-user-checkbox" 
                value={valueAdmin} 
                label={"Administrator"} 
                onChange={(e) => handleCheckboxChange(e, "admin")} 
                disabled={user.Roles !== "Admin"}  // Disable if the current user is not an Admin
              />
              <Checkbox 
                className="pulse-user-checkbox" 
                value={valueUser} 
                label={"User"} 
                onChange={(e) => handleCheckboxChange(e, "user")} 
                disabled={user.Roles !== "Admin"}  // Disable if the current user is not an Admin
              />
          </GridLayoutItem>
          <GridLayoutItem  row={4} col={2}>
          </GridLayoutItem>
          {valueAdmin ?           <GridLayoutItem className="user-role-section"  row={4} col={3} colSpan={3}>
              <img src={permissionchecks}></img> <span className="pulse-user-permission">Dashboard</span>
              <br />
              <img src={permissionchecks}></img> <span className="pulse-user-permission">Reports</span>
              <br />
              <img src={permissionchecks}></img> <span className="pulse-user-permission">User Management</span>
              <br />
          </GridLayoutItem> : 
                 <GridLayoutItem className="user-role-section"  row={4} col={3} colSpan={3}>
                    <img src={permissionchecks}></img> <span className="pulse-user-permission">Dashboard</span>
                    <br />
                    <img src={permissionchecks}></img> <span className="pulse-user-permission">Reports</span>
                    <br />
                </GridLayoutItem>}
          <GridLayoutItem  row={25} col={1} colSpan={5}>
            <div className="user-operation-buttons">
                <Button className="pulse-permission-window-button" onClick={()=>handleClick("Update")} >Save Changes</Button>
                <Button className="pulse-permission-window-cancel-button" onClick={()=>toggleDialog(null)}>Cancel</Button>
            </div>
          </GridLayoutItem>
        </GridLayout>
            </div>
          </div>
        </Window>
      )}
    </div>
    <div>
      {visibleRemove && (
        <Window
          title={"Remove User"}
          onClose={toggleRemoveDialog}
          initialLeft={500}
          initialTop={300}
          className="pulse-user-window pulse-user-window-remove"
          height={130}
          width={500}
        >
          <div>
            <div className="pulse-user-window-header">Remove User</div>
            <div>
              Do you want to delete user {firstName} {lastName} from Pulse?
            </div>
            <br />
                    <Button className="pulse-permission-window-button" onClick={() => handleClick("Remove")} >Yes</Button>
                    <Button className="pulse-permission-window-cancel-button" onClick={() => toggleRemoveDialog(null)}>No</Button>
                        </div>
                    </Window>
                )}
            </div>
        </div>
    );
};

export default CSUserPage;
