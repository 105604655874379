import React, { Component } from 'react';
import { GoogleMap, LoadScript, MarkerF, Autocomplete, InfoWindow, DrawingManagerF, Polygon, useJsApiLoader, MarkerClusterer } from '@react-google-maps/api';
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import ExpandedPole from "../../global_components/cs_pole_popup";
import ExpandedPoleLasso from "../../global_components/pole_popup_lasso";
import drawIcon from "../../assets/Vector1.png";
import offdrawIcon from "../../assets/Vector2.png";
import loadinggif from "../../assets/loading.gif";
import loadingtext from "../../assets/loadingmaps.png";
import withProject from "../../contexts/class_context_convert.js";
import withTabContext from "../../contexts/report_tab_context_convert.js";
import withHighlightedPoles from "../../contexts/highlighted_poles_class_context_convert.js";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import blackIcon from "../../assets/SymbologyIcons/blackIcon.png";
import unselectedBlueIcon from "../../assets/SymbologyIcons/unselectedBlue.png";
import unselectedRedIcon from "../../assets/SymbologyIcons/unselectedRed.png";
import unselectedGreenIcon from "../../assets/SymbologyIcons/unselectedGreen.png";
import unselectedBlackIcon from "../../assets/SymbologyIcons/unselectedBlack.png";
import unselectedYellowIcon from "../../assets/SymbologyIcons/unselectedYellow.png";
import unselectedOrangeIcon from "../../assets/SymbologyIcons/unselectedOrange.png";
import selectedBlueIcon from "../../assets/SymbologyIcons/selectedBlue.png";
import selectedBlackIcon from "../../assets/SymbologyIcons/selectedBlack.png";
import selectedRedIcon from "../../assets/SymbologyIcons/selectedRed.png";
import selectedGreenIcon from "../../assets/SymbologyIcons/selectedGreen.png";
import selectedOrangeIcon from "../../assets/SymbologyIcons/selectedOrange.png";
import selectedYellowIcon from "../../assets/SymbologyIcons/selectedYellow.png";
import auditedPin from "../../assets/auditedpin.png";
import notauditedPin from "../../assets/notauditedpin.png";
import { DateRangePicker as KenDateRangePicker } from "@progress/kendo-react-dateinputs";
import moment from 'moment'
import { bordersShowHideIcon } from '@progress/kendo-svg-icons';
import {
    Button,
    ButtonGroup,
    DropDownButton,
    DropDownButtonItem,
    FloatingActionButton,
    SplitButton,
    SplitButtonItem,
    Toolbar,
    ToolbarSeparator,
    ChipList,
} from "@progress/kendo-react-buttons";
import withEnvironmentConfig from '../../contexts/EnvironmentConfigContextClassWrapper';

const getMapOptions = () => {
    if (window.google && window.google.maps) {
        return {
            fullscreenControl: false,
            styles: [
                {
                    elementType: "labels",
                    featureType: "poi",
                    stylers: [{ visibility: "off" }],
                },
            ],
            mapTypeControl: true,
            mapTypeControlOptions: {
                position: window.google.maps.ControlPosition.TOP_RIGHT,
            },
        };
    }
    return {}; // Fallback if maps is not loaded
};


const containerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    borderColor: '#BFBFBF',
}

const containerStyleFullSize = {
    width: '100%',
    height: '81vh',
    borderRadius: '10px',
    borderColor: '#BFBFBF'
}

class CSPoleMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapData: [],
            currentSelectedPole: null,
            currentCenter: {
                lat: 39.87597539,
                lng: -86.06844941
            },
            showInfoWindow: true,
            checkedMaplegend: "No Status",
            originalData: null,
            showDrawer: true,
            showPulled: true,
            showHeavyVegetation: true,
            showPoleNotSet: true,
            showElectricPower: true,
            showPoleNeedsTopping: true,
            showNotDoublewood: true,
            showReadyToGo: true,
            showNoAccess: true,
            showComplexTransfers: true,
            showDoesNotExist: true,
            showForeignOwned: true,
            showOthers: true,
            filteredMapData: [], 
            currentPin: "default",
            loading: true,
            map: {},
            poleadderList: [],
            poleconditionList: [],
            poleauditstatusList: [],
            rawQuery: null,
            created_user: [],
            Reject_Status: [],
            created_user_checkbox: true,
            Reject_Status_checkbox: true,
            checkboxvalue: false,
            dateStart: null,
            dateEnd: null,
            showDate: {},
            counts: 0,
            drawingRec: null,
            lassoWindow: false,
            lassoData: null,
            drawOption: null,
            datevalue: null,
            audit_checkbox: true,
            zoomLevel: 16
        };
        this.autocomplete = null
        this.onLoad = this.onLoad.bind(this)
        this.onPlaceChanged = this.onPlaceChanged.bind(this)
        this.handleMarkerOnClick = this.handleMarkerOnClick.bind(this)
        this.handleMapOnClick = this.handleMapOnClick.bind(this)
        this.handlePoleonChange = this.handlePoleonChange.bind(this)
    }

    componentDidMount() {
        if (this.props.projectContext.selectedProject !== "") {
            this.populateMapData("");
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.projectContext.selectedProject !== prevProps.projectContext.selectedProject) {
            this.populateMapData("");
            this.handleClearMapFilter();
        }
    }

    handlePoleonChange(e, type, pinType) {
        var locationWaitingStatusList = ["Pole not Set", "Electric Power", "Heavy Vegetation", "Pole Needs Topping", "No Access"]
        var updatedMapData;
        if (type === this.state.checkedMaplegend) {
            this.setState({
                checkedMaplegend: "No Status",
                currentPin: "default",
                currentSelectedPole: null,
                loading: true
            }, () => {
                let boundInput = this.getBounds();
                // this.populateMapData(boundInput, "No Status");
            })
        }
        else {
            this.setState({
                checkedMaplegend: type,
                currentSelectedPole: null,
                currentPin: pinType,
                loading: true
            }, () => {
                let boundInput = this.getBounds();
                // this.populateMapData(boundInput, type);
            })
        }

    }

    handleMarkerOnClick(marker, e) {
        this.setState({
            currentSelectedPole: null,
        }, () => {
            this.setState({
                currentSelectedPole: marker,
            });
        })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            var searchInput = e.target.value;
            var lookupData = this.state.mapData;
            var test = lookupData.filter(function (data) {
                return lookupData !== null ? data.info.Pole_Number === searchInput : null
            });

            this.setState({
                currentSelectedPole: null,
                zoomLevel: null
            }, () => {
                this.setState({
                    currentSelectedPole: test.length === 0 ? null : test[0],
                    zoomLevel : 16
                });
            })
        }
    }

    handleMapOnClick() {
        this.setState({
            currentSelectedPole: null,
        })
    }

    handleDraw() {
        this.setState({
            drawOption: "rectangle",
        })
    }

    handleOffDraw() {
        this.state.drawingRec.setMap(null);
        this.setState({
            drawOption: "maker",
            lassoWindow: false
        })
    }

    onLoad(autocomplete) {
        this.autocomplete = autocomplete
    }

    updateFilteredMapData = () => {
        const { highlightedPoles } = this.props.highlightedPolesContext;
        const {
            mapData,
            showNotDoublewood,
            showPulled,
            showReadyToGo,
            showHeavyVegetation,
            showPoleNotSet,
            showElectricPower,
            showPoleNeedsTopping,
            showNoAccess,
            showComplexTransfers,
            showForeignOwned,
            showDoesNotExist,
            showOthers,
        } = this.state;

        const handledStatuses = [
            "Pulled",
            "Not Doublewood",
            "Ready to Go",
            "Heavy Vegetation",
            "Pole Not Set",
            "Electric Power",
            "Pole Needs Topping",
            "No Access",
            "Complex Transfers",
            "Foreign Owned",
            "Does Not Exist",
        ];

        const filtered = mapData.filter((item) => {
            const isHighlighted = highlightedPoles.includes(item.info ?.Globslid);

            // If highlighted poles exist, only show them
            if (highlightedPoles.length > 0) {
                return isHighlighted;
            }

            // Otherwise, apply the existing filters
            const status = item.info ?.Pole_Status;

            if (status === "Pulled" && !showPulled) return false;
            if (status === "Not Doublewood" && !showNotDoublewood) return false;
            if (status === "Ready to Go" && !showReadyToGo) return false;
            if (status === "Heavy Vegetation" && !showHeavyVegetation) return false;
            if (status === "Pole Not Set" && !showPoleNotSet) return false;
            if (status === "Electric Power" && !showElectricPower) return false;
            if (status === "Pole Needs Topping" && !showPoleNeedsTopping) return false;
            if (status === "No Access" && !showNoAccess) return false;
            if (status === "Complex Transfers" && !showComplexTransfers) return false;
            if (status === "Foreign Owned" && !showForeignOwned) return false;
            if (status === "Does Not Exist" && !showDoesNotExist) return false;
            if (!handledStatuses.includes(status) && !showOthers) return false;

            return true;
        });

        this.setState({ filteredMapData: filtered }, () => {
            if (highlightedPoles.length > 0 && filtered.length > 0) {
                const firstHighlightedPole = filtered.find((pole) =>
                    highlightedPoles.includes(pole.info ?.Globslid)
                );
                if (firstHighlightedPole) {
                    this.setState({
                        currentCenter: firstHighlightedPole.coords,
                        zoomLevel: 16, 
                    });
                }
            }
        });
    };

    onPlaceChanged() {
        //251-B110
        if (this.autocomplete !== null) {
            var searchCallback = this.autocomplete.getPlace();
            var searchInput = searchCallback.name.toString();
            var lookupData = this.state.mapData;
            var test = lookupData.filter(function (data) {
                return lookupData !== null ? data.info.Pole_Number === searchInput : null
            });
            this.setState({
                currentSelectedPole: null,
            }, () => {
                this.setState({
                    currentSelectedPole: test.length === 0 ? null : test[0],
                });
            })
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    showDrawer = () => {
        this.setState({
            showDrawer: !this.state.showDrawer
        })
    }

    getBounds = () => {
        let bounds = this.state.map.getBounds();
        let center = this.state.map.getCenter()

        let lat = center.lat();
        let long = center.lng();

        let ne = bounds.getNorthEast(); // LatLng of the north-east corner
        let sw = bounds.getSouthWest(); // LatLng of the south-west corner

        let bbox = {
            north: ne.lat(),
            east: ne.lng(),
            south: sw.lat(),
            west: sw.lng(),
            lat: lat,
            long: long
        };

        return bbox;
    }
    boundsCallBack = () => {
        this.setState({
            loading: true
        })
        setTimeout(() => {
            let boundInput = this.getBounds();
            // this.populateMapData(boundInput, this.state.checkedMaplegend);
        }, 500)
    }

    boundsZoomCallBack = () => {
        setTimeout(() => {
            this.setState({
                loading: true
            })
            let boundInput = this.getBounds();
            // this.populateMapData(boundInput, this.state.checkedMaplegend);
        }, 1000)
    }

    handleMapLoad = (map) => {
        this.setState((state) => ({ ...state, map }));
        setTimeout(() => {
            let boundInput = this.getBounds();
            // this.populateMapData(boundInput, this.state.checkedMaplegend);
        }, 500)
    }

    handleChange = (e) => {
        if (e.value.end != null) {
            this.setState({
                dateStart: moment(e.value.start).format('yyyy-MM-DD hh:mm:ss'),
                dateEnd: moment(e.value.end).format('yyyy-MM-DD hh:mm:ss'),
                showDate: e.value,
            }, () => { this.handleFilterUpdate() })
        }
    }

    handleCleanDate = () => {
        this.setState({
            dateStart: null,
            dateEnd: null,
            showDate: {}
        }, () => { this.handleFilterUpdate() })
    }

    handleClearMapFilter = () => {
        const { setHighlightedPoles } = this.props.highlightedPolesContext;
        setHighlightedPoles([]); 

        this.setState({
            showNotDoublewood: true,
            showPulled: true,
            showReadyToGo: true,
            showHeavyVegetation: true,
            showPoleNotSet: true,
            showElectricPower: true,
            showPoleNeedsTopping: true,
            showNoAccess: true,
            showComplexTransfers: true,
            showDoesNotExist: true,
            showForeignOwned: true,
            showOthers: true,
            filteredMapData: this.state.mapData,
            currentSelectedPole: null,
        });
    };

    handleGoToReport = () => {
        const { setSelectedTab } = this.props.tabContext;
        setSelectedTab(1);
        this.props.navigate('/report'); 

        this.setState({ currentSelectedPole: null });
    }

    handleFilterUpdate = () => {
        this.setState({
            loading: true
        })
        var rawQuery = "";
        var tempQuery = "";
        var tempQuery1 = "";
        var tempQuery2 = "";
        var tempQuery3 = "";
        var temparray = [];
        if (this.state.created_user.length > 0) {
            var tempString = this.state.created_user.map((value) => `${"'" + value + "'"}`).join(',');
            //console.log(tempString);
            tempQuery = "created_user In (" + tempString + ")";
        }
        else {
            tempQuery = "";
        }

        if (this.state.Reject_Status.length > 0) {
            var tempString = this.state.Reject_Status.map((value) => `${value}`).join(',');
            //console.log(tempString);
            tempQuery1 = "Reject_Status In (" + tempString + ")";
        }
        else {
            tempQuery1 = "";
        }

        if (this.state.dateEnd != null && this.state.dateStart != null) {
            var tempString = "created_date < '" + this.state.dateEnd + "' and created_date > '" + this.state.dateStart + "'";
            //console.log(tempString);
            tempQuery2 = tempString;
        }
        else {
            tempQuery2 = "";
        }
        if (this.state.audit_childbox_audited) {
            tempQuery3 = 'audit_user is not null'
        }

        if (tempQuery !== "") {
            temparray.push(tempQuery);
        }
        if (tempQuery1 !== "") {
            temparray.push(tempQuery1);
        }
        if (tempQuery2 !== "") {
            temparray.push(tempQuery2);
        }
        if (tempQuery3 !== "") {
            temparray.push(tempQuery3);
        }
        var rawQuery = temparray.map((value) => `${value}`).join(' and ');
        // rawQuery = tempQuery + (tempQuery == "" ||  tempQuery1 == "" ? "" : " And ") + tempQuery1 + tempQuery2 === "" ? "" : " and " + tempQuery2;
        this.populateMapData(rawQuery);
    }
    handleViewButtonOnClick = () => {
        //console.log("1");
    }

    handleCondition = (condition) => {
        if (condition === "1") {
            return "Non Reject";
        }
        else if (condition === "2") {
            return "Non Restorable Reject";
        }
        else if (condition === "3") {
            return "Priority Non Restorable Reject";
        }
        else if (condition === "4") {
            return "Priority Restorable Reject";
        }
        else if (condition === "5") {
            return "Restorable Reject";
        }
    }

    assignPoleSymbologyColor = (pole) => {
        const { hasHighlightedPoles } = this.props.highlightedPolesContext;

        if (!hasHighlightedPoles) {
            if (pole && pole.info && pole.info.Pole_Status) {
                switch (pole.info.Pole_Status) {
                    case "Pulled":
                    case "Not Doublewood":
                        return selectedBlueIcon;
                    case "Ready to Go":
                        return selectedGreenIcon;
                    case "Heavy Vegetation":
                    case "Pole Not Set":
                    case "Electric Power":
                    case "Pole Needs Topping":
                    case "No Access":
                        return selectedOrangeIcon;
                    case "Complex Transfers":
                        return selectedYellowIcon;
                    case "Foreign Owned":
                    case "Does Not Exist":
                        return selectedRedIcon;
                    default:
                        return selectedBlackIcon;
                }
            }
            return blackIcon;
        } else {
            if (pole.highlighted) {
                if (pole && pole.info && pole.info.Pole_Status) {
                    switch (pole.info.Pole_Status) {
                        case "Pulled":
                        case "Not Doublewood":
                            return selectedBlueIcon;
                        case "Ready to Go":
                            return selectedGreenIcon;
                        case "Heavy Vegetation":
                        case "Pole Not Set":
                        case "Electric Power":
                        case "Pole Needs Topping":
                        case "No Access":
                            return selectedOrangeIcon;
                        case "Complex Transfers":
                            return selectedYellowIcon;
                        case "Foreign Owned":
                        case "Does Not Exist":
                            return selectedRedIcon;
                        default:
                            return selectedBlackIcon;
                    }
                }
                return blackIcon;
            } 
        }
    };


    render() {
        const { highlightedPoles } = this.props.highlightedPolesContext;

        const legendWithoutFilterApplied = (
            <div className="pulse-map-legend-with-filter">
                <GridLayout
                    gap={{
                        rows: 6,
                        cols: 2,
                    }}
                >
                    <GridLayoutItem row={1} col={1} className="map-icon-layoutitem">
                        <img src={selectedBlueIcon} alt="Not Doublewood Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={1} col={2}>
                        <div className="legend-row">
                            Not Doublewood
                    <Checkbox
                        checked={this.state.showNotDoublewood}
                        className="legend-checkbox"
                        style={{ backgroundColor: this.state.showNotDoublewood ? "#1260CE" : "#FFFFFF", borderColor: "#051A38", borderWidth: "1px", width: "20px", height: "20px" }}
                        onChange={(e) => {
                              const isChecked = e.syntheticEvent.target.checked;
                              this.setState(
                                  {
                                     showNotDoublewood: isChecked, showPulled: isChecked, currentSelectedPole: null, },
                                     this.updateFilteredMapData
                                  );
                            }}
                    />
                        </div>
                    </GridLayoutItem>
                    <GridLayoutItem row={2} col={1} className="map-icon-layoutitem">
                        <img src={selectedGreenIcon} alt="Ready to Go Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={2} col={2}>
                        <div className="legend-row">
                            Ready to Go
                    <Checkbox
                                checked={this.state.showReadyToGo}
                                style={{ backgroundColor: this.state.showReadyToGo ? "#20790A" : "#FFFFFF", borderColor: "#051A38", borderWidth: "1px", width: "20px", height: "20px" }}
                                className="legend-checkbox"
                                onChange={(e) => {
                                    const isChecked = e.syntheticEvent.target.checked;
                                    this.setState(
                                        { showReadyToGo: isChecked, currentSelectedPole: null },
                                        this.updateFilteredMapData
                                    );
                                }}
                            />
                        </div>
                    </GridLayoutItem>

                    <GridLayoutItem row={3} col={1} className="map-icon-layoutitem">
                        <img src={selectedOrangeIcon} alt="No Access Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={3} col={2}>
                        <div className="legend-row">
                            No Access
                    <Checkbox
                                checked={this.state.showNoAccess}
                                className="legend-checkbox"
                                style={{ backgroundColor: this.state.showNoAccess ? "#FF8000" : "#FFFFFF", borderColor: "#051A38", borderWidth: "1px", width: "20px", height: "20px" }}
                                onChange={(e) => {
                                    const isChecked = e.syntheticEvent.target.checked;
                                    this.setState(
                                        {
                                            showHeavyVegetation: isChecked,
                                            showPoleNotSet: isChecked,
                                            showElectricPower: isChecked,
                                            showPoleNeedsTopping: isChecked,
                                            showNoAccess: isChecked,
                                            currentSelectedPole: null
                                            },
                                        this.updateFilteredMapData
                                    );
                                }}
                            />
                        </div>
                    </GridLayoutItem>

                    <GridLayoutItem row={4} col={1} className="map-icon-layoutitem">
                        <img src={selectedYellowIcon} alt="Complex Transfers Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={4} col={2}>
                        <div className="legend-row">
                            Complex Transfers
                    <Checkbox
                                checked={this.state.showComplexTransfers}
                                style={{ backgroundColor: this.state.showComplexTransfers ? "#FFC517" : "#FFFFFF", borderColor: "#051A38", borderWidth: "1px", width: "20px", height: "20px" }}
                                className="legend-checkbox"
                                onChange={(e) => {
                                    const isChecked = e.syntheticEvent.target.checked;
                                    this.setState(
                                        { showComplexTransfers: isChecked, currentSelectedPole: null, },
                                        this.updateFilteredMapData
                                    );
                                }}
                            />
                        </div>
                    </GridLayoutItem>

                    <GridLayoutItem row={5} col={1} className="map-icon-layoutitem">
                        <img src={selectedRedIcon} alt="Does Not Exist Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={5} col={2}>
                        <div className="legend-row">
                            Does Not Exist
                    <Checkbox
                                checked={this.state.showDoesNotExist}
                                style={{ backgroundColor: this.state.showDoesNotExist ? "#FF0000" : "#FFFFFF", borderColor: "#051A38", borderWidth: "1px", width: "20px", height: "20px" }}
                                className="legend-checkbox"
                                onChange={(e) => {
                                    const isChecked = e.syntheticEvent.target.checked;
                                    this.setState(
                                        { showDoesNotExist: isChecked, showForeignOwned: isChecked, currentSelectedPole: null },
                                        this.updateFilteredMapData
                                    );
                                }}
                            />
                        </div>
                    </GridLayoutItem>

                    <GridLayoutItem row={6} col={1} className="map-icon-layoutitem">
                        <img src={selectedBlackIcon} alt="Others" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={6} col={2}>
                        <div className="legend-row">
                            Others
                    <Checkbox
                                checked={this.state.showOthers}
                                style={{ backgroundColor: this.state.showOthers ? "#000000" : "#FFFFFF", borderColor: "#051A38", borderWidth: "1px", width: "20px", height: "20px" }}
                                className="legend-checkbox"
                                onChange={(e) => {
                                    const isChecked = e.syntheticEvent.target.checked;
                                    this.setState(
                                        { showOthers: isChecked, currentSelectedPole: null },
                                        this.updateFilteredMapData
                                    );
                                }}
                            />
                        </div>
                    </GridLayoutItem>
                </GridLayout>
            </div>
        );

        const legendWithFilterApplied = (
            <div className="pulse-map-legend">
                <GridLayout
                    gap={{
                        rows: 6,
                        cols: 2,
                    }}
                >
                    <GridLayoutItem row={1} col={1} className="map-icon-layoutitem">
                        <img src={selectedBlueIcon} alt="Not Doublewood Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={1} col={2}>
                        <div className="legend-row">Not Doublewood</div>
                    </GridLayoutItem>

                    <GridLayoutItem row={2} col={1} className="map-icon-layoutitem">
                        <img src={selectedGreenIcon} alt="Ready to Go Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={2} col={2}>
                        <div className="legend-row">Ready to Go</div>
                    </GridLayoutItem>

                    <GridLayoutItem row={3} col={1} className="map-icon-layoutitem">
                        <img src={selectedOrangeIcon} alt="No Access Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={3} col={2}>
                        <div className="legend-row">No Access</div>
                    </GridLayoutItem>

                    <GridLayoutItem row={4} col={1} className="map-icon-layoutitem">
                        <img src={selectedYellowIcon} alt="Complex Transfers Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={4} col={2}>
                        <div className="legend-row">Complex Transfers</div>
                    </GridLayoutItem>

                    <GridLayoutItem row={5} col={1} className="map-icon-layoutitem">
                        <img src={selectedRedIcon} alt="Does Not Exist Pole" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={5} col={2}>
                        <div className="legend-row">Does Not Exist</div>
                    </GridLayoutItem>

                    <GridLayoutItem row={6} col={1} className="map-icon-layoutitem">
                        <img src={selectedBlackIcon} alt="Others" />
                    </GridLayoutItem>
                    <GridLayoutItem className="pulse-map-legend-grid" row={6} col={2}>
                        <div className="legend-row">Others</div>
                    </GridLayoutItem>
                </GridLayout>
            </div>
        );


        return (
            <>
                {
                    this.state.loading
                        ?
                        <div className='nimbly2-map-loader'><img className='nimbly2-map-loader-gif' src={loadinggif} /><img className='nimbly2-map-loader-text' src={loadingtext} /></div> : null
                }
                <div className={this.state.loading ? "nimbly-map-loading" : "map-container"} >
                    <GoogleMap
                        mapContainerStyle={this.props.fullSizeMap ? containerStyleFullSize : containerStyle}
                        center={this.state.currentCenter}
                        zoom={this.state.zoomLevel}
                        options={getMapOptions()}
                        onFail={error => console.log(error)}
                    >
                        <div className="map-button-group">
                            <Button
                                onClick={() => this.handleClearMapFilter()}
                                className="map-button"
                            >
                                Reset Map Filter
                            </Button>
                            {highlightedPoles && highlightedPoles.length > 0 && (
                                <Button
                                    onClick={() => this.handleGoToReport()}
                                    className="map-button"
                                >
                                    Go to Report
                                </Button>
                            )}
                        </div>
                        <input
                            onKeyUp={(e) => this.handleKeyPress(e)}
                            type="text"
                            placeholder="Search by Pole Number"
                            style={{
                                boxSizing: 'border-box',
                                border: '1px solid transparent',
                                width: '300px',
                                height: '32px',
                                padding: '0 12px',
                                borderRadius: '3px',
                                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                fontSize: '14px',
                                outline: 'none',
                                textOverflow: 'ellipsis',
                                position: 'absolute',
                                right: '10px',
                                top: '10px',   
                            }}
                        />
                        {
                        //<Autocomplete
                        //    onLoad={this.onLoad}
                        //    onPlaceChanged={this.onPlaceChanged}
                        ////onFail={error => console.log(error)}
                        //>
                        //    <input
                        //        type="text"
                        //        placeholder="Search by Pole Number"
                        //        style={{
                        //            boxSizing: `border-box`,
                        //            border: `1px solid transparent`,
                        //            width: `300px`,
                        //            height: `32px`,
                        //            padding: `0 12px`,
                        //            borderRadius: `3px`,
                        //            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        //            fontSize: `14px`,
                        //            outline: `none`,
                        //            textOverflow: `ellipses`,
                        //            position: "absolute",
                        //            left: "85%",
                        //            marginLeft: "-120px",
                        //            marginTop: "10px"
                        //        }}
                        //    />
                        //    </Autocomplete>
                        }
                        {this.state.filteredMapData == null && this.state.filteredMapData.length <= 0 ? "loading" : this.state.filteredMapData.map((item, index) => (
                            <MarkerF
                                key={index}
                                position={item.coords}
                                onClick={(e) => this.handleMarkerOnClick(item, e)}
                                icon={this.assignPoleSymbologyColor(item)}
                            >
                                {this.state.currentSelectedPole !== null && this.state.currentSelectedPole.info.Globslid === item.info.Globslid ?
                                    <InfoWindow
                                        key={index}
                                        position={item.coords}
                                    >
                                        <ExpandedPole
                                            pole={item}
                                        />
                                    </InfoWindow> : null
                                }
                            </MarkerF>
                            )
                            )
                        }
                        <></>
                    </GoogleMap>
                    {
                        //<div className="pulse-map-view-button-container">
                        //    <Button className="pulse-map-view-button" onClick={() => this.handleViewButtonOnClick()}>View in Report Wizard</Button>
                        //</div>
                    }
                    {
                        !this.state.loading && (
                            highlightedPoles && highlightedPoles.length > 0
                                ? legendWithFilterApplied
                                : legendWithoutFilterApplied
                        )
                    }
                </div>
            </>
        )
    }

    async populateMapData(rawQuery) {
        const { selectedProject, GCP_Project, Dataset_ID } = this.props.projectContext;
        const { highlightedPoles } = this.props.highlightedPolesContext;
        const { environmentConfig } = this.props.environmentConfigContext;


        this.setState({ loading: true });
        fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Secrets/GetNimblyWebAPIKey', {
            method: 'GET',
            headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" },
            // mode: 'no-cors',
        })
            .then((res) => {
                return res.text();;
            })
            .then((data) => {
                //fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/GetRSPoles', {
                    fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/TestGetPulsePoles', {
                    method: 'POST',
                    //headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', 'ApiKey': data, },
                    headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        StartDateRemoval: "",
                        EndDateRemoval: "",
                        StartDateAudit: "",
                        EndDateAudit: "",
                        Pole_Number: "",
                        Pole_Status: "",
                        Pull_Type: "this is for the map, should not run1123221",
                        State: "",
                        Region: "",
                        Project: selectedProject,
                        GCP_Project: GCP_Project,
                        Dataset_ID: Dataset_ID
                    })
                })
                    .then((res) => {
                        return res.json();;
                    })
                    .then((data) => {
                        const markerList = [];
                        const poleadderList = [];
                        const poleconditionList = [];
                        for (var index in data) {
                            var pole = data[index];
                            var obj = {};
                            var poleInfo = {};
                            poleInfo["Audit_User"] = null;
                            poleInfo["Audit_Date"] = null;
                            let lat = 0;
                            let long = 0;
                            if (pole && pole.fieldDict && pole.fieldDict.Location_Details && pole.fieldDict.Location_Details.Coordinates) {

                                let arr = pole.fieldDict.Location_Details.Coordinates.split("-");
                                if (arr.length == 2) {

                                    lat = Number(arr[0]);
                                    long = -(Number(arr[1]));
                                }
                            }


                            if (pole && pole.fieldDict && pole.fieldDict.Poles && pole.fieldDict.Poles.Pole_Number) {
                                poleInfo["info"].Photos = pole.photosDict;
                            }

                            poleInfo["info"] = pole && pole.fieldDict && pole.fieldDict.Poles ? pole.fieldDict.Poles : null;
                            poleInfo["Attachers"] = pole && pole.attachmentsDict && pole.attachmentsDict ? Object.values(pole.attachmentsDict) : [];

                            obj["lat"] = lat;
                            obj["lng"] = long;

                            poleInfo["allData"] = data[index];

                            poleInfo["coords"] = obj;


                            poleInfo.info = {

                            }
                            poleInfo["info"].Lat = lat.toString();
                            poleInfo["info"].Long = long.toString();
                            if (pole && pole.fieldDict && pole.fieldDict.Poles && pole.fieldDict.Poles.Audit_User) {
                                poleInfo["Audit_User"] = pole.fieldDict.Poles.Audit_User;
                            }
                            if (pole && pole.fieldDict && pole.fieldDict.Poles && pole.fieldDict.Poles.Audit_Date) {
                                poleInfo["Audit_Date"] = pole.fieldDict.Poles.Audit_Date;
                            }
                            if (pole && pole.fieldDict && pole.fieldDict.Poles && pole.fieldDict.Poles["Pole Status"]) {
                                poleInfo["info"]["Pole_Status"] = pole.fieldDict.Poles["Pole Status"];
                            }
                            if (pole && pole.fieldDict && pole.fieldDict.Poles && pole.fieldDict.Poles["Pole Number"]) {
                                poleInfo["info"].Pole_Number = pole.fieldDict.Poles["Pole Number"];
                            }
                            if (pole && pole.fieldDict && pole.fieldDict.Poles && pole.fieldDict.Poles.GlobalID) {
                                poleInfo["info"].Globslid = pole.fieldDict.Poles.GlobalID;
                            }

                            poleInfo["highlighted"] = false;

                            markerList.push(poleInfo);
                        }

                        markerList.forEach((marker) => {
                            if (highlightedPoles.includes(marker.info ?.Globslid)) {
                                marker.highlighted = true;
                            }
                        });

                        this.setState({
                            currentCenter: markerList[1].coords
                        })

                        if (rawQuery == "") {
                            this.setState({
                                mapData: markerList,
                                filteredMapData: markerList,
                                loading: false,
                                poleadderList: poleadderList,
                                poleconditionList: poleconditionList,
                                counts: markerList.length,
                            }, () => {
                                this.updateFilteredMapData();
                            });
                        }
                        else {
                            this.setState({
                                mapData: markerList,
                                filteredMapData: markerList,
                                loading: false,
                                counts: markerList.length,
                            });
                        }
                        this.updateFilteredMapData();
                    });
            });
    }
}
export default withEnvironmentConfig(withProject(withHighlightedPoles(withTabContext(CSPoleMap))));